import {Button} from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import {media} from "assets/css/media";

export const PureButton = styled.button<{isDisabled?: boolean}>`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: ${({isDisabled}) => (isDisabled ? "initial" : "pointer")};
`;

export const PrimaryButton = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 8px;
	gap: 8px;

	max-width: 320px;
	min-width: 120px;
	width: 100%;
	height: 44px;
	border-radius: 100px;

	font-family: "Gotham", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	text-align: center;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	color: #ffffff;

	background: var(--primaryColor);
	border: 1px solid var(--primaryColor);

	&:disabled {
		background: #c7c7cc;
		border: 1px solid #c7c7cc;
		color: #fff;

		&:hover {
			background: #c7c7cc;
			border: 1px solid #c7c7cc;
		}
	}

	&:hover {
		background: #fff;
		color: var(--primaryColor);
	}

	&.slide {
		transition: all ease 0.6s;
		box-shadow: inset 0 0 0 0 transparent;
		&:hover {
			background: none;
			box-shadow: inset 22em 0 0 0 var(--primaryColor);
		}

		&:focus {
			background: none;
			box-shadow: inset 22em 0 0 0 var(--primaryColor);
		}
	}
`;
export const SecondaryButton = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 40px;
	gap: 8px;

	//max-width: 320px;
	width: 100%;
	height: 44px;
	border-radius: 100px;

	font-family: "Gotham", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	text-align: center;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	color: var(--secondaryColor);

	background: transparent;
	border: 1px solid var(--secondaryColor);

	&:disabled {
		background: #c7c7cc;
		border: 1px solid #c7c7cc;

		&:hover {
			background: #c7c7cc;
			border: 1px solid #c7c7cc;
		}
	}

	&:hover {
		background: var(--secondaryColor);
		color: #fff;
	}
`;

export const SecondaryButtonInvert = styled(SecondaryButton)`
	background: transparent;
	color: #fff;
	border: 1px solid #fff;

	&:hover {
		background: var(--secondaryColor);
		border: 1px solid var(--secondaryColor);
		color: #fff;
	}
`;

export const TextButton = styled(Button)`
	font-family: "Nunito", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 140%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-decoration-line: underline;
	text-transform: uppercase;
	color: #fff;
	padding: 6px 0;
	min-width: unset;

	@media (min-width: ${media.mobile}) {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	&:hover {
		background: transparent;
	}
`;

export const BurgerIcon = styled.div`
	width: 28px;
	height: 24px;
	position: relative;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;

	&.open span:nth-child(1) {
		transform: rotate(45deg);
		top: 0px;
		left: 2px;
	}

	&.open span:nth-child(2) {
		width: 0%;
		opacity: 0;
	}

	&.open span:nth-child(3) {
		transform: rotate(-45deg);
		top: 20px;
		left: 2px;
		width: 102%;
	}

	span {
		display: block;
		position: absolute;
		height: 4px;
		width: 100%;
		background: #fff;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 0.25s ease-in-out;

		&:nth-child(1) {
			top: 0px;
			transform-origin: left center;
		}

		&:nth-child(2) {
			top: 8px;
			transform-origin: left center;
		}

		&:nth-child(3) {
			top: 16px;
			transform-origin: left center;
		}
	}
`;

const HamburgerButtonWrapper = styled(PureButton)`
	padding: 0;
	position: absolute;
	margin-right: 15px;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
`;

interface IBurgerButton {
	onClick: () => void;
	className?: string;
}

export const BurgerButton: React.FC<IBurgerButton> = ({onClick, className = ""}) => (
	<HamburgerButtonWrapper onClick={onClick}>
		<BurgerIcon className={className}>
			<span />
			<span />
			<span />
		</BurgerIcon>
	</HamburgerButtonWrapper>
);
