import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {isEqual} from "lodash";
import type {ChangeEvent, FocusEvent, SyntheticEvent} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {
	CITY_REQUIREMENTS,
	DATE_FORMAT,
	PASSWORD_REQUIREMENTS,
	POSTCODE_REQUIREMENTS,
} from "data/constants";
import {SelectChangeEvent} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import type {ICountriesStore, ICountry} from "data/stores/countries/countries.store";
import type {IRegistrationPayload} from "data/providers/api/user.api.provider";
import type {IStatesStore, IState} from "data/stores/states/states.store";
import {trackAdobeAnalyticsRegister} from "data/utils/analytics";

interface IRegistrationFormElement extends HTMLFormElement {
	username: HTMLInputElement;
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	email: HTMLInputElement;
	gender: HTMLInputElement;
	country: HTMLInputElement;
	address_1: HTMLInputElement;
	address_2: HTMLInputElement;
	city: HTMLInputElement;
	state: HTMLInputElement;
	postcode: HTMLInputElement;
	favTeam: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	terms: HTMLInputElement;
	optInEu: HTMLInputElement;
	optInUs: HTMLInputElement;
	dob: HTMLInputElement;
}

export interface IRegistrationController extends ViewController {
	get i18n(): ILocalizationStore;
	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get userData(): Record<string, string>;
	get countriesList(): ICountry[];
	get statesList(): IState[];
	get isSubmitDisabled(): boolean;
	get isCountryUS(): boolean;

	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
	handleInputSetValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleValidatePassword: (event: FocusEvent<HTMLInputElement>) => void;
	handleValidatePostcode: (event: FocusEvent<HTMLInputElement>) => void;
	handleValidateCity: (event: FocusEvent<HTMLInputElement>) => void;
	handleSelectChange: (event: SelectChangeEvent<string>) => void;
	handleSetDate: (value: Dayjs | null) => void;
	fetchStates: () => void;
}

@injectable()
export class RegistrationController implements IRegistrationController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace: string = "";
	@observable private _displayName: string = "";
	@observable private _firstName: string = "";
	@observable private _lastName: string = "";
	@observable private _dob: string = "";
	// dayjs().format(DATE_FORMAT);
	@observable private _gender: string = "";
	@observable private _country: string = "";
	@observable private _address_1: string = "";
	@observable private _address_2: string = "";
	@observable private _city: string = "";
	@observable private _state: string = "";
	@observable private _postcode: string = "";
	@observable private _favTeam: string = "";
	@observable private _isSubmitDisabled: boolean = true;

	get i18n() {
		return this._i18nStore;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get userData() {
		return {
			displayName: this._displayName,
			firstName: this._firstName,
			lastName: this._lastName,
			gender: this._gender,
			country: this._country,
			address_1: this._address_1,
			address_2: this._address_2,
			city: this._city,
			state: this._state,
			postcode: this._postcode,
			favTeam: this._favTeam,
			dob: this._dob,
		};
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isSubmitDisabled(): boolean {
		return this._isSubmitDisabled;
	}

	get countriesList(): ICountry[] {
		return this._countries.list;
	}

	get statesList(): IState[] {
		return this._states.list;
	}

	get isCountryUS(): boolean {
		return isEqual(this._country, "USA");
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.CountriesStore) private _countries: ICountriesStore,
		@inject(Bindings.StatesStore) private _states: IStatesStore
	) {
		makeAutoObservable(this);
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action register(payload: IRegistrationPayload) {
		this._requestState = RequestState.PENDING;
		return this._userStore.register(payload).catch(this.onError);
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();

		const {
			username,
			firstName,
			lastName,
			gender,
			country,
			address_1,
			address_2,
			city,
			state,
			postcode,
			favTeam,
			email,
			password,
			confirmPassword,
			terms,
			optInEu,
			optInUs,
		} = event.currentTarget;

		const validateList = [
			{field: email, error: "Please provide a valid email address", place: "email"},
			{field: username, error: "Please provide your display name", place: "username"},
			{field: firstName, error: "Please provide your first name", place: "firstName"},
			{field: lastName, error: "Please provide your last name", place: "lastName"},
			{field: gender, error: "Please provide your gender", place: "gender"},
			{field: country, error: "Please provide your country", place: "country"},
			{field: favTeam, error: "Please provide your favorite team", place: "favTeam"},
			{field: password, error: PASSWORD_REQUIREMENTS, place: "password"},
			{field: terms, error: "Please accept Terms & Conditions", place: "terms"},
			...(this.isCountryUS
				? [
						{
							field: address_1,
							error: "Please provide your address",
							place: "address_1",
						},
						{field: city, error: CITY_REQUIREMENTS, place: "city"},
						{field: state, error: "Please provide your state", place: "state"},
						{field: postcode, error: POSTCODE_REQUIREMENTS, place: "postcode"},
				  ]
				: []),
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		// if (email.value !== confirmEmail.value) {
		// 	return this.reportError("Emails do not match", "confirmEmail");
		// }

		if (password.value !== confirmPassword.value) {
			return this.reportError("Passwords do not match", "confirmPassword");
		}

		const payload = {
			username: username.value,
			email: email.value,
			password: password.value,
			firstName: firstName.value,
			lastName: lastName.value,
			gender: gender.value,
			country: country.value,
			favTeam: favTeam.value,
			dob: this._dob,
			terms: terms.checked,
			optInEu: optInEu.checked,
			optInUs: optInUs.checked,
			...(this.isCountryUS && {
				address_1: address_1.value,
				address_2: address_2.value,
				city: city.value,
				state: state.value,
				postcode: postcode.value,
			}),
			// lang: this._i18nStore.lang,
		};

		this._requestState = RequestState.PENDING;
		void this.register(payload)
			.then(() => trackAdobeAnalyticsRegister())
			.catch(this.onError);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleFormOnChange = (event: ChangeEvent<IRegistrationFormElement>) => {
		const {
			username,
			firstName,
			lastName,
			gender,
			country,
			favTeam,
			email,
			password,
			confirmPassword,
			terms,
			address_1,
			city,
			state,
			postcode,
		} = event.currentTarget;

		const dataArr = [
			username.value,
			firstName.value,
			lastName.value,
			gender.value,
			country.value,
			favTeam.value,
			email.value,
			password.value,
			confirmPassword.value,
			terms.checked,
			this._dob,
			...(this.isCountryUS ? [address_1.value, city.value, state.value, postcode.value] : []),
			// dayjs(dob.value as Dayjs | null).isValid(),
		];

		if (event.target.name !== "password") {
			this._errorMsg = null;
			this._errorPlace = "";
		}

		if (dataArr.every(Boolean)) {
			this._isSubmitDisabled = false;

			return;
		}

		this._isSubmitDisabled = true;
	};

	@action handleInputSetValue = (event: ChangeEvent<HTMLInputElement>) => {
		const {value, name} = event.target;
		if (name === "firstName") {
			this._firstName = value;
			return;
		}
		if (name === "lastName") {
			this._lastName = value;
			return;
		}
		if (name === "address_1") {
			this._address_1 = value;
			return;
		}
		if (name === "address_2") {
			this._address_2 = value;
			return;
		}
		if (name === "city") {
			this._city = value;
			return;
		}
		if (name === "postcode") {
			this._postcode = value;
			return;
		}
		this._displayName = value.replace("@", "");
	};

	@action handleValidatePassword = (event: FocusEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			this.reportError(PASSWORD_REQUIREMENTS, "password");
		}
	};

	@action handleValidatePostcode = (event: FocusEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			this.reportError(POSTCODE_REQUIREMENTS, "postcode");
		}
	};

	@action handleValidateCity = (event: FocusEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			this.reportError(CITY_REQUIREMENTS, "city");
		}
	};

	@action handleSelectChange = (event: SelectChangeEvent<string>) => {
		const {value, name} = event.target;
		if (name === "country") {
			this._country = value;
			return;
		}
		if (name === "gender") {
			this._gender = value;
			return;
		}
		if (name === "state") {
			this._state = value;
			return;
		}
		this._favTeam = value;
	};

	@action handleSetDate = (value: Dayjs | null) => {
		// if(dayjs(value).isValid()){
		this._dob = value ? value.format(DATE_FORMAT) : dayjs().format(DATE_FORMAT);
		// }
	};

	fetchStates = () => {
		void this._states.fetchStates();
	};

	dispose(): void {
		return;
	}

	init() {
		void this._countries.fetchCountries();
	}
}
