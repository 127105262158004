import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squds.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {
	type IRegistrationController,
	RegistrationController,
} from "views/pages/registration/registration.controller";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	GamezoneController,
	type IGamezoneController,
} from "views/pages/gamezone/gamezone.controller";
import {type IMenuStore, MenuStore} from "data/stores/menu/menu.store";
import {type IMenuController, MenuController} from "views/components/menu/menu.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {type IModalsController, ModalsController} from "views/controllers/modals/modals.controller";
import {HeaderController, type IHeaderController} from "views/components/header/header.controller";
import {IStatesStore, StatesStore} from "data/stores/states/states.store";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();
	bind<IMenuStore>(Bindings.MenuStore).to(MenuStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStatesStore>(Bindings.StatesStore).to(StatesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IRegistrationController>(Bindings.RegistrationController).to(RegistrationController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IGamezoneController>(Bindings.GamezoneController).to(GamezoneController);
	bind<IMenuController>(Bindings.MenuController).to(MenuController);
	bind<IModalsController>(Bindings.ModalsController).to(ModalsController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
});
