import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {IMenuStore} from "data/stores/menu/menu.store";

export interface IHeaderController extends ViewController {
	get isAuthorized(): boolean;
	get user(): IUser | undefined;
	get i18n(): ILocalizationStore;
	get isOpenMenu(): boolean;

	toggleMenu: () => void;
	closeMenu: () => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get user() {
		return this._userStore.user;
	}

	get i18n() {
		return this._i18nStore;
	}

	get isOpenMenu(): boolean {
		return this._menuStore.isOpenMenu;
	}

	toggleMenu = () => {
		this._menuStore.toggleMenu();
	};

	closeMenu = () => {
		this._menuStore.closeMenu();
	};

	dispose() {
		return;
	}

	init(param: void) {
		return;
	}
}
