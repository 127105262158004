import React from "react";
import {Modal} from "@mui/material";
import {isEqual} from "lodash";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {
	CloseBtn,
	ModalBody,
	ModalBox,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalText,
	ModalTitle,
} from "views/components/modals/common/common.component";
import {ReactComponent as IconChecked} from "assets/img/icon/icon-checked.svg";
import {ReactComponent as IconClose} from "assets/img/icon/close.svg";
import {PrimaryButton, SecondaryButton} from "views/components/style";

export const ModalAccountUpdated: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {i18n, modalContent, visibleModal, close} = controller;
	const isVisible = isEqual(visibleModal, ModalType.ACCOUNT_UPDATED);

	if (!isVisible) {
		return null;
	}

	const title = modalContent?.title;
	const message = modalContent?.message;

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<ModalContent>
					<ModalHeader>
						<IconChecked />
					</ModalHeader>
					<ModalBody>
						<ModalTitle>{title}</ModalTitle>
						<ModalText>{message}</ModalText>
					</ModalBody>
					<CloseBtn onClick={close}>
						<IconClose />
					</CloseBtn>
					<ModalFooter>
						<PrimaryButton
							fullWidth
							onClick={close}
							href={"/your-picks/"}
							variant="contained"
							size="large">
							{i18n.t("modal.button.leave", "Leave")}
						</PrimaryButton>
						<SecondaryButton onClick={close} fullWidth variant="contained" size="large">
							{i18n.t("modal.button.stay", "Stay")}
						</SecondaryButton>
					</ModalFooter>
				</ModalContent>
			</ModalBox>
		</Modal>
	);
});
