import styled from "@emotion/styled";
import {Box, Container} from "@mui/material";
import {media} from "assets/css/media";
import linesBgDesktop from "assets/img/line_bg_desktop.png";
import linesBgMobile from "assets/img/line_bg_mobile.png";
import mainBgDesktop from "assets/img/main_bg_desktop.png";
import mainBgMobile from "assets/img/main_bg_mobile.png";

export const GameContainer = styled.main`
	//display: flex;
	flex: 1;
	font-family: "Gotham", sans-serif;
	font-size: 16px;
	color: #fff;
	//background: linear-gradient(264deg, #0041f9 0%, #ff0019 100%);
	background: url(${mainBgDesktop}) no-repeat 50% 0;
	background-size: cover;

	@media (max-width: ${media.mobile}) {
		//background: linear-gradient(255deg, #0041f9 0%, #ff0019 100%);
		background: url(${mainBgMobile}) no-repeat 50% 0;
		background-size: cover;
		padding: 30px 0 0;
	}
`;

export const LinesContainer = styled.section`
	flex: 1;
	font-family: "Gotham", sans-serif;
	font-size: 16px;
	color: #fff;
	background: url(${linesBgDesktop}) no-repeat 50% 0;
	background-size: cover;

	@media (max-width: ${media.mobile}) {
		background: url(${linesBgMobile}) no-repeat 50% 0;
		background-size: cover;
	}
`;

export const AuthSection = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1 1 100%;
	padding: 5px 0;
	box-sizing: border-box;

	background: url(${mainBgDesktop}) no-repeat 50% 0;
	background-size: cover;

	@media (max-width: ${media.mobile}) {
		background: url(${mainBgMobile}) no-repeat 50% 0;
		background-size: cover;
		padding: 30px 0 0;
	}
`;
export const AuthWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 20px 40px;
	gap: 20px;

	max-width: 600px;
	width: 100%;
	margin: 20px auto 20px;

	background: #fff;
	border: 1px solid #ffffff;
	border-radius: 8px;

	color: var(--thirdColor);
	font-family: var(--fontFamilySecond);

	@media (max-width: 768px) {
		width: 94%;
	}

	form {
		width: 100%;
		max-width: 320px;
		margin: 0 auto;
		color: var(--thirdColor);

		h2 {
			font-family: var(--fontFamilyThird);
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 104%;
			text-align: center;
			letter-spacing: -0.02em;
			text-transform: uppercase;
		}

		h6 {
			font-family: var(--fontFamilySecond);
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 130%;
			text-align: center;
		}

		a {
			font-weight: 400;
		}

		.MuiInputBase-input {
			color: var(--thirdColor) !important;

			&:-webkit-autofill {
				color: var(--thirdColor) !important;
				-webkit-text-fill-color: var(--thirdColor);
			}
		}

		.MuiTextField-root,
		.MuiSelect-select {
			//background: rgba(255, 255, 255, 0.09);
			background: rgba(0, 0, 0, 0.1);
			border-radius: 4px 4px 0 0;
			color: var(--thirdColor);
		}

		.MuiSelect-select {
			color: var(--thirdColor);
		}

		.MuiInputBase-formControl {
			color: var(--thirdColor);

			&:after {
				//border-color: #c81414;
				border-color: var(--primaryColor);
			}

			svg {
				fill: var(--thirdColor);

				path {
					fill: var(--thirdColor);
				}
			}
		}

		.MuiInputLabel-formControl {
			color: #a3a3a3;
		}

		.MuiSwitch-track {
			background-color: #616d82;
		}

		.Mui-checked + .MuiSwitch-track {
			background-color: #4b2eb8;
		}

		.Mui-checked {
			.MuiSwitch-thumb {
				background-color: var(--primaryColor);
			}
		}
	}
`;

export const LogoContainer = styled(Box)<{size: "lg" | "md" | "sm"}>`
	margin: 25px auto 0;
	box-sizing: border-box;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const DesktopMainContainer = styled(Container)`
	padding: 50px 5px 25px;

	@media (max-width: ${media.mobile}) {
		padding: 25px 5px;
	}

	form {
		width: 100%;
		margin: 0 auto;
		color: var(--thirdColor);

		&.personal_data {
			.row {
				flex: 1 1 48%;
				max-width: 48%;

				@media (max-width: ${media.mobile}) {
					flex: 1 1 100%;
					max-width: 100%;
				}
			}
		}

		h2 {
			font-family: "GothamCond", sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 104%;
			text-align: center;
			letter-spacing: -0.02em;
			text-transform: uppercase;
		}

		h6 {
			font-family: "Gotham", sans-serif;
			font-style: normal;
			font-weight: 350;
			font-size: 16px;
			line-height: 130%;
			text-align: center;
		}

		.MuiInputBase-input {
			color: var(--thirdColor) !important;

			&:-webkit-autofill {
				color: var(--thirdColor) !important;
				-webkit-text-fill-color: var(--thirdColor);
			}
		}

		.MuiTextField-root,
		.MuiSelect-select {
			//background: rgba(255, 255, 255, 0.09);
			background: rgba(0, 0, 0, 0.1);
			border-radius: 4px 4px 0 0;
			color: var(--thirdColor);
		}

		.MuiSelect-select {
			color: var(--thirdColor);
		}

		.MuiInputBase-formControl {
			color: var(--thirdColor);

			&:after {
				//border-color: #c81414;
				border-color: var(--primaryColor);
			}

			svg {
				fill: var(--thirdColor);

				path {
					fill: var(--thirdColor);
				}
			}
		}

		.MuiInputLabel-formControl {
			color: #a3a3a3;
		}

		.MuiSwitch-track {
			background-color: #616d82;
		}

		.Mui-checked + .MuiSwitch-track {
			background-color: #4b2eb8;
		}

		.Mui-checked {
			.MuiSwitch-thumb {
				background-color: var(--primaryColor);
			}
		}
	}
`;

export const FormContainer = styled(Box)`
	width: 100%;
	max-width: 320px;
	margin: 0 auto;
	color: var(--thirdColor);
	font-family: var(--fontFamilySecond);
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		font-family: var(--fontFamilyThird);
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 104%;
		text-align: center;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		margin: 15px auto;
	}

	p {
		font-family: var(--fontFamilySecond);
		font-style: normal;
		font-weight: 350;
		font-size: 16px;
		line-height: 130%;
		text-align: center;
		color: var(--thirdColor);
	}
`;
