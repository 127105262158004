import styled from "@emotion/styled";
import {Box} from "@mui/material";

export const ModalBox = styled(Box)`
	background: #fff;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 600px;
	min-width: 320px;
	border: 1px solid #001436;
	border-radius: 8px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 20px 40px;
	gap: 24px;
	box-sizing: border-box;
	outline: none;
`;

export const ModalContent = styled(Box)`
	max-height: 85vh;
	overflow: auto;
	color: #001436;

	// FIX for iOS
	@supports (-webkit-touch-callout: none) {
		max-height: 75vh;
	}
`;

export const ModalBody = styled(Box)`
	color: #001436;
`;

export const ModalHeader = styled.div`
	padding: 16px 0;
	display: flex;
	justify-content: center;
`;

export const ModalTitle = styled.h3`
	font-family: var(--fontFamilyThird);
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 104%;
	text-align: center;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	margin: 20px 0;
`;

export const ModalSubTitle = styled.h4`
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 16px;
	text-align: center;
`;

export const ModalText = styled.p`
	font-family: var(--fontFamilySecond);
	font-style: normal;
	font-weight: 350;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	margin: 0px 0 20px;
`;
export const ModalFooter = styled(Box)`
	padding: 15px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;

	button,
	a {
		max-width: unset;
		width: 100%;
		flex: 1 1 50%;
	}
`;
export const CloseBtn = styled(Box)`
	width: 20px;
	height: 20px;
	position: absolute;
	top: 15px;
	right: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg {
		width: 18px;
		height: 18px;

		path {
			fill: #001436;
		}
	}
`;
