export interface IMediaPoints {
	large_desktop: string;
	desktop: string;
	tablet: string;
	mobile: string;
	small_mobile: string;
}

export const media: IMediaPoints = {
	large_desktop: "1440px",
	desktop: "1200px",
	tablet: "960px",
	mobile: "768px",
	small_mobile: "330px",
};
