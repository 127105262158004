import {inject, injectable} from "inversify";
import {computed, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {includes, toLower} from "lodash";

export interface IStaticContent {
	page: number;
	previous_page: null;
	next_page: null;
	per_page: number;
	page_count: number;
	count: number;
	sort_by: string;
	sort_order: string;
}

export interface ISections extends IStaticContent {
	sections: {
		id: number;
		url: string;
		html_url: string;
		category_id: number;
		position: number;
		sorting: string;
		created_at: string;
		updated_at: string;
		name: string;
		description: string;
		locale: string;
		source_locale: string;
		outdated: boolean;
		parent_section_id: null;
		theme_template: string;
	}[];
}

export interface ICategories extends IStaticContent {
	categories: {
		id: number;
		url: string;
		html_url: string;
		position: number;
		created_at: string;
		updated_at: string;
		name: string;
		description: string;
		locale: string;
		source_locale: string;
		outdated: boolean;
	}[];
}

export interface IArticles extends IStaticContent {
	articles: {
		id: number;
		url: string;
		html_url: string;
		author_id: number;
		comments_disabled: boolean;
		draft: boolean;
		promoted: boolean;
		position: number;
		vote_sum: number;
		vote_count: number;
		section_id: number;
		created_at: string;
		updated_at: string;
		name: string;
		title: string;
		source_locale: string;
		locale: string;
		outdated: boolean;
		outdated_locales: unknown[];
		edited_at: string;
		user_segment_id: null;
		permission_group_id: number;
		label_names: string[];
		body: string;
	}[];
}

export type TArticle = IArticles["articles"][number];

export interface IStaticContentStore {
	get sections(): ISections["sections"];
	get articles(): IArticles["articles"];

	fetchStaticContent(): Promise<void>;
}

@injectable()
export class StaticContentStore implements IStaticContentStore {
	@observable private _helpSections?: ISections["sections"];
	@observable private _helpComponents?: IArticles["articles"];

	@computed get sections() {
		return this._helpSections ?? [];
	}

	@computed get articles() {
		return this._helpComponents ?? [];
	}

	constructor(
		@inject(Bindings.LocalizationStore) private readonly _localizationStore: ILocalizationStore,
		@inject(Bindings.JSONProvider) private readonly _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	public async fetchStaticContent() {
		const locale = (this._localizationStore.locale || "en-US").toLowerCase();

		const [categories, sections, articles] = await Promise.all([
			this._jsonProvider.helpCategories(locale),
			this._jsonProvider.helpSections(locale),
			this._jsonProvider.helpArticles(locale),
		]);

		const helpComponentsId = categories.data.categories.find(({name}) =>
			includes(toLower(name), "help")
		)?.id;

		runInAction(() => {
			this._helpSections = sections.data.sections.filter(
				(section) => section.category_id === helpComponentsId
			);

			this._helpComponents = articles.data.articles;
		});
	}
}
