import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import React, {ChangeEvent} from "react";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {ILoginPayload} from "data/providers/api/auth.api.provider";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {extractErrorMessage} from "data/utils";
import {size} from "lodash";

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

export interface ILoginController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<ILoginForm>) => void;
	login: (params: ILoginPayload) => Promise<void>;
	handleFormOnChange: () => void;
	handleInputOnChange: (event: ChangeEvent<HTMLInputElement>) => void;

	get i18n(): ILocalizationStore;
	get error(): Record<string, string> | null;
	get isFormDisabled(): boolean;
	get isLoginDisable(): boolean;
}

@injectable()
export class LoginController implements ILoginController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _email = "";
	@observable private _password = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get i18n() {
		return this._i18nStore;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	get isLoginDisable() {
		return ![size(this._email), size(this._password)].every(Boolean);
	}

	// saveLocalLineup = () => {
	// 	const euLineup = JSON.parse(localStorage.getItem("euLineup") || "[]") as number[];
	// 	const usLineup = JSON.parse(localStorage.getItem("usLineup") || "[]") as number[];
	//
	// 	if ([size(euLineup), size(usLineup)].every(Boolean)) {
	// 		this._teamStore.setLineup(TeamRegion.Europe, euLineup);
	// 		this._teamStore.setLineup(TeamRegion.Usa, usLineup);
	// 		this._teamStore.setIsSelectedStart();
	// 	}
	// };

	// clearLocalLineup = () => {
	// 	localStorage.removeItem("euLineup");
	// 	localStorage.removeItem("usLineup");
	// };

	// saveTeam = () => {
	// 	const isFullEuLineup = size(compact(this._teamStore.euLineup)) === 12;
	// 	const isFullUsLineup = size(compact(this._teamStore.usLineup)) === 12;
	//
	// 	if (isFullEuLineup) {
	// 		void this._teamStore.createTeam({
	// 			lang: this.i18n.lang,
	// 			lineup: this._teamStore.euLineup,
	// 			team: TeamRegion.Europe,
	// 		});
	// 		// .then(() => this.getSavedModal());
	// 	}
	//
	// 	if (isFullUsLineup) {
	// 		void this._teamStore.createTeam({
	// 			lang: this.i18n.lang,
	// 			lineup: this._teamStore.usLineup,
	// 			team: TeamRegion.Usa,
	// 		});
	// 		// .then(() => this.getSavedModal());
	// 	}
	// };

	// onSkipActive = () => {
	// 	const isSkipped = JSON.parse(localStorage.getItem("isSkipped") || "0") as number;
	// 	if (isSkipped) {
	// 		this._teamStore.setIsTeamSkipped();
	// 		localStorage.removeItem("isSkipped");
	// 	}
	// };

	private mapErrorMsg(error: string | undefined) {
		switch (error) {
			case "Invalid email or password":
				return "Please enter a valid email or password";
			case undefined:
				return "Oops, something went wrong , please try again later";
			default:
				return error;
		}
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = this.mapErrorMsg(error);
		this._errorPlace = place;

		return true;
	}

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {value, name} = event.target;

		if (name === "email") {
			this._email = value;
			return;
		}

		this._password = value;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		console.log("error----", error);
		this.reportError(extractErrorMessage(error));
	};

	@action login(payload: ILoginPayload) {
		this._requestState = RequestState.PENDING;
		return this._userStore.login(payload).catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		if (!email.checkValidity()) {
			return this.reportError("Please provide a valid email address", "email");
		}

		if (!password.checkValidity()) {
			return this.reportError("Please provide a valid password", "password");
		}

		void this.login({
			email: email.value,
			password: password.value,
			// lang: this._i18nStore.lang,
		}).catch(this.onError);
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
