import {injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";

export interface IMenuStore {
	get isOpenMenu(): boolean;

	toggleMenu(): void;
	closeMenu(): void;
}

@injectable()
export class MenuStore implements IMenuStore {
	@observable private _isOpenMenu: boolean = false;

	get isOpenMenu(): boolean {
		return this._isOpenMenu;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action toggleMenu() {
		this._isOpenMenu = !this._isOpenMenu;
	}

	@action closeMenu() {
		this._isOpenMenu = false;
	}
}
