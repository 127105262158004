import {observer} from "mobx-react";
import React, {Fragment} from "react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import type {IMenuController} from "views/components/menu/menu.controller";
import styled from "@emotion/styled";
import {Box, Button, useMediaQuery} from "@mui/material";
import {getOpenState} from "data/utils/helper";
import {NavLink, useNavigate} from "react-router-dom";
import {ReactComponent as CloseIcon} from "assets/img/icon/close-white.svg";
import {media} from "assets/css/media";
import {ReactComponent as GamezoneLogo} from "assets/img/gamezone/gamezone_logo.svg";
import {RYDER_CUP_GAMES} from "data/constants";

const MobileMenuWrapper = styled(Box)`
	position: fixed;
	width: 100%;
	max-width: 320px;
	overflow: auto;
	top: 0;
	bottom: 0;
	left: 0;
	background: #0041f9;
	z-index: 999;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	transition: all ease 0.5s;
	background-size: cover;

	&.open {
		transform: translateX(0);
	}

	&.closed {
		transform: translateX(-100%);
	}
`;

const MenuContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 10px 0;

	a {
		font-family: var(--fontFamilySecond);
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 118%;

		display: flex;
		justify-content: flex-start;
		align-items: center;
		letter-spacing: 0.06em;
		text-transform: capitalize;
		color: #ffffff;

		opacity: 0.9;
		transition: all ease 0.4s;
		padding: 5px 20px;
		border-bottom: 1px solid #011536;
		height: 40px;

		&.active {
			opacity: 1;
			font-weight: 450;
			color: var(--thirdColor);
		}

		&.project_link {
			font-family: var(--fontFamilyBase);
			font-style: normal;
			font-weight: 450;
			font-size: 16px;
			line-height: 118%;

			display: flex;
			align-items: center;
			letter-spacing: 0.06em;
			text-transform: capitalize;
			color: #ffffff;

			opacity: 0.9;
			transition: all ease 0.4s;
			padding: 5px 20px;
			border-bottom: 1px solid var(--secondaryColor);
			background: #011536;
			height: 40px;
			border-radius: 0;
		}
	}
`;
const MenuHeader = styled(Box)`
	min-height: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 0 10px;
	box-sizing: border-box;
	position: relative;

	button {
		width: 40px;
		height: 40px;
		min-width: 40px;

		svg {
			width: 20px;
			height: 20px;
		}
	}

	a {
		border: none !important;
		text-decoration: none;
	}
`;
const MenuOverlay = styled(Box)<{isVisible: boolean}>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.69);
	z-index: ${({isVisible}) => (isVisible ? 899 : -1)};
`;
const CloseButton = styled(Button)`
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
	width: auto;
	height: auto;
	min-width: unset;
`;
const ProjectName = styled(Box)`
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 450;
	font-size: 16px;
	line-height: 118%;

	display: flex;
	align-items: center;
	letter-spacing: 0.06em;
	text-transform: capitalize;
	color: #ffffff;

	opacity: 0.9;
	transition: all ease 0.4s;
	padding: 5px 20px;
	border-bottom: 1px solid var(--secondaryColor);
	background: #011536;
	height: 40px;

	a {
		height: auto;
		padding: 5px 0;
		font-weight: 400;
	}
`;

export const MobileMenu: React.FC = observer(() => {
	const {i18n, isOpenMenu, closeMenu, isAuthorized} = useViewController<IMenuController>(
		Bindings.MenuController
	);
	const isMobile = useMediaQuery(`(max-width: ${media.mobile})`);
	const navigate = useNavigate();

	const toMain = () => {
		navigate("/");
	};

	return isMobile ? (
		<MenuOverlay isVisible={isOpenMenu} onClick={closeMenu}>
			<MobileMenuWrapper className={getOpenState(isOpenMenu)}>
				<MenuContainer>
					<MenuHeader>
						<CloseButton onClick={closeMenu}>
							<CloseIcon />
						</CloseButton>

						<a href="/" className="logo_link">
							<GamezoneLogo width="160px" height="42px" />
						</a>
					</MenuHeader>
					<ProjectName onClick={toMain}>
						{i18n.t("common.project.gamezone", "Game Zone")}
					</ProjectName>
					{isAuthorized ? (
						<NavLink to="/my-account" onClick={closeMenu}>
							{i18n.t("navigation.account", "My Account")}
						</NavLink>
					) : (
						<Fragment>
							<NavLink to="/login" onClick={closeMenu}>
								{i18n.t("navigation.login", "Login")}
							</NavLink>
							<NavLink to="/registration" onClick={closeMenu}>
								{i18n.t("navigation.register", "Register")}
							</NavLink>
						</Fragment>
					)}
					{RYDER_CUP_GAMES.filter((el) => !el.disabled).map((el, index) => (
						<Button
							key={index}
							href={el.url}
							onClick={closeMenu}
							className="project_link">
							{i18n.t(el.locoKey, el.name)}
						</Button>
					))}
				</MenuContainer>
			</MobileMenuWrapper>
		</MenuOverlay>
	) : null;
});
