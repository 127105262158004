import React, {Fragment, ReactNode} from "react";
import {PagePreloader} from "views/components/preloader";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import {IBootstrapController} from "views/components/bootstrap/bootstrap.controller";
import {Bindings} from "data/constants/bindings";

export const Bootstrap: React.FC<{children: ReactNode}> = observer(({children}) => {
	const {isReady} = useViewController<IBootstrapController>(Bindings.BootstrapController);
	return isReady ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
