import React, {Fragment} from "react";
import {ModalError} from "views/components/modals/error/modal_error.component";
import {ModalAccountUpdated} from "views/components/modals/account/account_updated.component";

export const Modals: React.FC = () => {
	return (
		<Fragment>
			<ModalError />
			<ModalAccountUpdated />
		</Fragment>
	);
};
