import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const Home = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const Registration = lazy(
	retryFailLoad(() => import("views/pages/registration/registration.page"))
);
const ForgotPassword = lazy(
	retryFailLoad(() => import("views/pages/forgot_password/forgot_password.page"))
);
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);
const Gamezone = lazy(retryFailLoad(() => import("views/pages/gamezone/gamezone.page")));

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<NotAuthOnlyRoute />}>
			<Route path="/login" element={<Home />} />
			<Route path="/registration" element={<Registration />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/my-account" element={<MyAccount />} />
		</Route>
		<Route index element={<Gamezone />} />
		<Route path="components" element={<ComponentsReview />} />
		<Route path="help/*" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default RootRoutes;
