export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum NetworkError {
	NotAuthorized = 401,
	TokenExpired = 406,
	LongUsername = 422,
}

export enum ModalType {
	LOGIN,
	REGISTRATION,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_CONFIRM,
	RESET_PASSWORD,
	ERROR,
	TUTORIAL,
	INFO,
	LEAGUE_JOINED,
	LEAGUE_REMOVE_USER,
	LEAGUE_LEAVE,
	LEAGUE_REMOVE,
	PASSWORD_CHANGED,
	ACCOUNT_UPDATED,
	SECOND_REGISTRATION,
	TEAM_SAVED,
	LOSE_PICKS,
	EMPTY_LINEUP,
	SKIP,
}

export enum FavoriteTeam {
	Europe = "europe",
	Usa = "usa",
	RyderCup = "ryder_cup",
}

export enum Gender {
	Male = "male",
	Female = "female",
	Other = "other",
	NotToSay = "not_to_say",
}
