import {retryFailLoad} from "data/utils";
import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const SecretPage = lazy(retryFailLoad(() => import("views/pages/secret/secret.page")));

export const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route path="*" element={<SecretPage />} />
	</Routes>
);

export default SecretGateRoutes;
