import twitterIcon from "assets/img/social/twitter-icon.svg";
// import facebookIcon from "assets/img/social/facebook-icon.svg";
import instagramIcon from "assets/img/social/instagram-icon.svg";
import youtubeIcon from "assets/img/social/youtube-icon.svg";
import linkedInIcon from "assets/img/social/linkedin-icon.svg";
import pickemLogo from "assets/img/gamezone/pickem_logo.svg";
import fantasyLogo from "assets/img/gamezone/fantasy_logo_new.svg";
import pinpointLogo from "assets/img/gamezone/pinpoint_logo_new.svg";
import pickemCardIcon from "assets/img/gamezone/pickem-card-icon.png";
import fantasyCardIcon from "assets/img/gamezone/fantasy-card-icon-new.svg";
import pinpointCardIcon from "assets/img/gamezone/pinpoint-card-icon-new.svg";

export const SITE_URL = process.env.REACT_SITE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_PATTERN =
	"^(?:(?:(?:0[1-9]|[12]\\d|3[01])/(?:0[13578]|1[02])|(?:0[1-9]|[12]\\d|30)/(?:0[469]|11)|(?:0[1-9]|1\\d|2[0-8])/02)/(?!0000)\\d{4}|(?:(?:0[1-9]|[12]\\d)/02/(?:(?!0000)(?:[02468][048]|[13579][26])00|(?!..00)\\d{2}(?:[02468][048]|[13579][26]))))$";

export const POSTCODE_PATTERN = "^[0-9]{5}$";
export const POSTCODE_REQUIREMENTS = "Zipcode must be 5 digits";

export const CITY_PATTERN = "^[a-zA-Z\\s]+$";
export const CITY_REQUIREMENTS = "No numbers or special characters allowed";

export const LANDING_URL = "/landing";
export const GAME_PAGE_URL = "/game";

export const SOCIAL_LINKS = [
	{
		name: "twitter",
		link: "https://twitter.com/rydercup",
		logo: twitterIcon,
	},
	{
		name: "instagram",
		link: "https://www.instagram.com/therydercup/",
		logo: instagramIcon,
	},
	{
		name: "youtube",
		link: "https://www.youtube.com/RyderCup",
		logo: youtubeIcon,
	},
	// {
	// 	name: "facebook",
	// 	link: "https://www.facebook.com/RyderCupUSA/",
	// 	logo: facebookIcon,
	// },
	{
		name: "LinkedIn",
		link: "https://www.linkedin.com/company/the-ryder-cup",
		logo: linkedInIcon,
	},
];

interface IGames {
	position: number;
	name: string;
	url: string;
	locoKey: string;
	gameLogo: string;
	cardBg: string;
	title: string;
	description: string;
	disabled?: boolean;
	button?: string;
}

export const RYDER_CUP_GAMES: IGames[] = [
	{
		position: 1,
		name: "Fantasy",
		url: "/fantasy/",
		locoKey: "common.project.fantasy",
		gameLogo: fantasyLogo,
		cardBg: fantasyCardIcon,
		title: "game.title.fantasy",
		description: "game.description.fantasy",
		disabled: true,
		button: "game.button.fantasy",
	},
	{
		position: 2,
		name: "Pinpoint",
		url: "/pinpoint/",
		locoKey: "common.project.pinpoint",
		gameLogo: pinpointLogo,
		cardBg: pinpointCardIcon,
		title: "game.title.pinpoint",
		description: "game.description.pinpoint",
		disabled: true,
		button: "game.button.pinpoint",
	},
	{
		position: 3,
		name: "Your Picks",
		url: "/your-picks/",
		locoKey: "common.project.pickem",
		gameLogo: pickemLogo,
		cardBg: pickemCardIcon,
		title: "game.title.pickem",
		description: "game.description.pickem",
		disabled: true,
		button: "game.button.picks",
	},
];
