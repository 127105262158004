import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IMenuStore} from "data/stores/menu/menu.store";

export interface IGamezoneController extends ViewController {
	get i18n(): ILocalizationStore;
	get isAuthorized(): boolean;
	get user(): IUser | undefined;
}

@injectable()
export class GamezoneController implements IGamezoneController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore
	) {
		makeAutoObservable(this);
	}

	get i18n() {
		return this._i18nStore;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get user() {
		return this._userStore.user;
	}

	dispose() {
		return;
	}

	init(param: void) {
		return;
	}
}
