import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ChangeEvent, FocusEvent, SyntheticEvent} from "react";
import {ModalType, RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {SelectChangeEvent} from "@mui/material";
import {PASSWORD_REQUIREMENTS} from "data/constants";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IMyAccountFormElement extends HTMLFormElement {
	username: HTMLInputElement;
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	email: HTMLInputElement;
	optIn: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
}

interface IPasswordChangeFormElement extends HTMLFormElement {
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	get i18n(): ILocalizationStore;
	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get userCurrentData(): Record<string, string | boolean>;
	get user(): IUser;
	get isDataUpdateDisabled(): boolean;
	get isPasswordUpdateDisabled(): boolean;

	handleDataFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handlePasswordChangeFormSubmit: (event: SyntheticEvent<IPasswordChangeFormElement>) => void;
	handleClearDataErrorOnChange: () => void;
	handleClearPasswordErrorOnChange: (event: SyntheticEvent<IPasswordChangeFormElement>) => void;
	handleChangeUsernameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleBlurUsernameValue: (event: FocusEvent<HTMLInputElement>) => void;
	handleSelectChange: (event: SelectChangeEvent<string>) => void;
	handleValidatePassword: (event: FocusEvent<HTMLInputElement>) => void;
	handleLogout: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isDataFormChanged = false;
	@observable private _isPasswordFormChanged = false;
	@observable private _displayName = this.user.username;
	@observable private _favTeam: string = this.user.favTeam;
	@observable private _firstName: string = this.user.firstName;
	@observable private _lastName: string = this.user.lastName;
	@observable private _email: string = this.user.email;
	@observable private _optIn: boolean = this.user.optIn;
	@observable private _isNotificationsEnabled: boolean = this.user.isNotificationsEnabled;

	get i18n() {
		return this._i18nStore;
	}

	get isDataUpdateDisabled() {
		return this.isFormDisabled || !this._isDataFormChanged;
	}

	get isPasswordUpdateDisabled() {
		return this.isFormDisabled || !this._isPasswordFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get userCurrentData() {
		return {
			username: this._displayName,
			firstName: this._firstName,
			lastName: this._lastName,
			email: this._email,
			favTeam: this._favTeam,
			optIn: this._optIn,
			isNotificationsEnabled: this._isNotificationsEnabled,
		};
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
		// this._displayName = this._userStore.user!.username;
	}

	toCorrectUsername = (value: string) => {
		return (this._displayName = value.replaceAll("@", ""));
	};

	getAccountUpdatedModal = (title: string, message: string) => {
		return this._modalStore.showModal(ModalType.ACCOUNT_UPDATED, {
			title,
			message,
		});
	};

	@action handleChangeUsernameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this.toCorrectUsername(event.target.value);
	};

	@action handleBlurUsernameValue = (event: FocusEvent<HTMLInputElement>) => {
		this.toCorrectUsername(event.target.value);
	};

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleDataFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {username, firstName, lastName, email, isNotificationsEnabled} = event.currentTarget;

		const validateList = [
			{field: email, error: "Please provide a valid email address", place: "email"},
			{field: username, error: "Please provide your display name", place: "username"},
			{field: firstName, error: "Please provide your first name", place: "firstName"},
			{field: lastName, error: "Please provide your last name", place: "lastName"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		if (username.value.includes("@")) {
			return this.reportError("Display name contains forbidden symbols", "username");
		}

		const payload = {
			username: username.value,
			firstName: firstName.value,
			lastName: lastName.value,
			email: email.value,
			favTeam: this._favTeam,
			// optIn: optIn.checked,
			isNotificationsEnabled: isNotificationsEnabled.checked,
			// lang: this._i18nStore.lang,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isDataFormChanged = false;
				})
			)
			.then(() =>
				this.getAccountUpdatedModal(
					this._i18nStore.t("modal.account_updated.title", "Account updated!"),
					this._i18nStore.t(
						"modal.account_updated.description",
						"You have successfully updated your account."
					)
				)
			)
			.catch(this.onError);
	};

	@action public handlePasswordChangeFormSubmit = (
		event: SyntheticEvent<IPasswordChangeFormElement>
	) => {
		event.preventDefault();

		const {password, confirmPassword} = event.currentTarget;

		const validateList = [{field: password, error: PASSWORD_REQUIREMENTS, place: "password"}];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		if (password.value !== confirmPassword.value) {
			return this.reportError("Passwords do not match", "confirmPassword");
		}

		const payload = {
			password: password.value,
			// lang: this._i18nStore.lang,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isPasswordFormChanged = false;
				})
			)
			.then(() =>
				this.getAccountUpdatedModal(
					this._i18nStore.t("modal.password_updated.title", "Password Updated!"),
					this._i18nStore.t(
						"modal.password_updated.description",
						"You have successfully updated your password."
					)
				)
			)
			.catch(this.onError);
	};

	@action handleValidatePassword = (event: FocusEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			this.reportError(PASSWORD_REQUIREMENTS, "password");
		}
	};

	@action handleSelectChange = (event: SelectChangeEvent<string>) => {
		const {value} = event.target;
		this._favTeam = value;
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleClearDataErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isDataFormChanged = true;
	};

	@action handleClearPasswordErrorOnChange = (
		event: SyntheticEvent<IPasswordChangeFormElement>
	) => {
		const {password, confirmPassword} = event.currentTarget;
		this._errorMsg = null;
		this._errorPlace = "";

		[password.value, confirmPassword.value].every(Boolean)
			? (this._isPasswordFormChanged = true)
			: (this._isPasswordFormChanged = false);
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.logout().then(this.onSuccess).catch(this.onError);
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
