export function trackAdobeAnalyticsRegister() {
	if (!window.dataLayer) {
		console.warn("No window.dataLayer found");
		return;
	}

	window.dataLayer.push({
		event: "formSubmit",
		// pageName: getPageName(section),
		// language: i18n.language,
		// userStatus: getUserStatus(),
	});
}
