import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";

export interface IState {
	name: string;
	code: string;
}

export interface IStatesStore {
	fetchStates(): Promise<void>;
	list: IState[];
}

@injectable()
export class StatesStore implements IStatesStore {
	@observable private _list: IState[] = [];

	get list() {
		return this._list;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action async fetchStates() {
		const {data} = await this._jsonProvider.states();

		runInAction(() => {
			this._list = data;
		});
	}
}
