import React from "react";
import {Modal} from "@mui/material";
import {isEqual} from "lodash";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {
	CloseBtn,
	ModalBody,
	ModalBox,
	ModalContent,
	ModalHeader,
	ModalText,
	ModalTitle,
} from "views/components/modals/common/common.component";
import {ReactComponent as IconInfo} from "assets/img/icon/icon-info.svg";
import {ReactComponent as IconClose} from "assets/img/icon/close.svg";

export const ModalError: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, modalContent, close} = controller;
	const isVisible = isEqual(visibleModal, ModalType.ERROR);

	if (!isVisible) {
		return null;
	}

	const title = modalContent?.title;
	const message = modalContent?.message;

	return (
		<Modal open={isVisible}>
			<ModalBox>
				<ModalContent>
					<ModalHeader>
						<IconInfo />
					</ModalHeader>
					<ModalBody>
						<ModalTitle>{title}</ModalTitle>
						<ModalText>{message}</ModalText>
					</ModalBody>
					<CloseBtn onClick={close}>
						<IconClose />
					</CloseBtn>
				</ModalContent>
			</ModalBox>
		</Modal>
	);
});
